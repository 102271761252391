<template>
  <div class="register-bg d-flex align-items-center justify-content-center">
    <div class="w-100">
      <b-row class="justify-content-center align-items-center m-0">
        <b-col md="9">
          <b-row class="header-panel">
            <b-col>
              <p>ยินดีต้อนรับ</p>
            </b-col>
          </b-row>
          <b-row class="body-panel justify-content-center card-container">
            <b-col md="5" class="bg-black p-0">
              <div class="picture"></div>
            </b-col>
            <b-col md="7" class="px-md-0">
              <div v-if="isMember">
                <p class="text-title">เข้าสู่ระบบ</p>
                <div class="p-3">
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">
                        โทร (Telephone) <span class="text-danger">*</span>
                      </p>
                    </b-col>
                    <b-col>
                      <InputText
                        name="telephone"
                        placeholder="เบอร์โทรศัพท์ 10 หลัก"
                        v-model="form.telephone"
                        :isValidate="$v.form.telephone.$error"
                        :v="$v.form.telephone"
                        @onKeypress="isNumber($event)"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="4">
                      <p class="text-title-input">
                        วันเกิด (Birthday) <span class="text-danger">*</span>
                      </p>
                    </b-col>
                    <b-col>
                      <InputDatePickerFilter
                        name="birthday"
                        ref="birthday"
                        v-model="form.birthday"
                        @input="changeBirthday"
                        :maxDatetime="now"
                        :isValidate="$v.form.birthday.$error"
                        :v="$v.form.birthday"
                      />
                    </b-col>
                  </b-row>
                  <div class="text-right">
                    <b-button
                      variant="outline-primary-color"
                      class="px-4 py-2 rounded-pill mb-2 mr-3"
                      :disabled="isLoading"
                      @click="handleMember(false)"
                    >
                      ย้อนกลับ
                    </b-button>
                    <b-button
                      variant="primary-color"
                      class="px-4 py-2 rounded-pill mb-2"
                      :disabled="isLoading"
                      @click="login"
                    >
                      ตกลง
                    </b-button>
                  </div>
                </div>
              </div>
              <div v-else-if="step == 1">
                <div v-if="isLoading" class="loading-panel">
                  <OtherLoading />
                </div>
                <div v-else>
                  <p class="text-title">ข้อมูลส่วนตัว</p>
                  <div class="p-3 register-box">
                    <ProfileFields
                      :form="form"
                      :v="$v"
                      :fields="fields"
                      @otherGender="handleOtherForm"
                      @otherPrefix="handleOtherForm"
                    />

                    <div class="text-right">
                      <b-button
                        variant="primary-color"
                        class="px-4 py-2 rounded-pill mb-2"
                        :disabled="isLoading"
                        @click="handleNext(2)"
                      >
                        ต่อไป
                      </b-button>
                      <br />
                      <b-button
                        variant="link"
                        :disabled="isLoading"
                        @click="handleMember(true)"
                        v-if="form.isRegister"
                      >
                        เป็นสมาชิกอยู่แล้ว
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="step == 2">
                <p class="text-title">ข้อมูลที่อยู่</p>

                <div class="p-3 register-box">
                  <AddressFields :form="address" :v="$v" :fields="fields" />
                  <div v-if="dynamicField.length == 0">
                    <p class="desc-privacy mb-2">
                      คลิกเลือกด้านล่าง เพื่อให้เราเข้าใจท่านมากขึ้น
                      และนำเสนอประสบการณ์ที่ดีที่สุดให้กับท่าน / Let us
                      understand you more to deliver you the best experience.
                      Check the box below and stay in touch!
                    </p>
                    <div class="mb-2">
                      <b-form-checkbox
                        v-model="form.is_consent"
                        :value="1"
                        :unchecked-value="0"
                      >
                        <label class="privacy-txt">
                          ฉันต้องการรับสิทธิพิเศษและโปรโมชั่น ตามที่ระบุไว้ใน
                          <router-link to="#" target="_blank"
                            >นโยบายความเป็นส่วนตัว</router-link
                          >
                          / I would like to receive my privileges specified in
                          <router-link to="#" target="_blank"
                            >Privacy Policy</router-link
                          >
                        </label>
                      </b-form-checkbox>
                    </div>
                    <div class="mb-2">
                      <b-form-checkbox
                        v-model="form.is_privacy_policy"
                        :value="1"
                        :unchecked-value="0"
                      >
                        <label class="privacy-txt">
                          ฉันยอมรับ
                          <router-link to="#" target="_blank"
                            >นโยบายความเป็นส่วนตัว</router-link
                          >
                          และ
                          <router-link to="#" target="_blank"
                            >ข้อกำหนดเงื่อนไข</router-link
                          >
                          / I accept the
                          <router-link to="#" target="_blank"
                            >Privacy Policy</router-link
                          >
                          and acknowledge the
                          <router-link to="#" target="_blank"
                            >Terms and Conditions</router-link
                          >
                        </label>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="text-right">
                    <div v-if="form.user_guid">
                      <b-button
                        variant="outline-primary-color"
                        class="px-4 py-2 rounded-pill mb-2"
                        :disabled="isLoading"
                        @click="backRegister"
                      >
                        กลับไป Register
                      </b-button>
                      <b-button
                        variant="outline-primary-color"
                        class="px-4 py-2 rounded-pill mb-2 mx-2"
                        :disabled="isLoading"
                        @click="step = 1"
                      >
                        ย้อนกลับ
                      </b-button>
                      <b-button
                        variant="primary-color"
                        class="px-4 py-2 rounded-pill mb-2"
                        :disabled="isLoading || !form.is_privacy_policy"
                        @click="save"
                      >
                        อัปเดต
                      </b-button>
                    </div>
                    <div v-else-if="dynamicField.length > 0">
                      <b-button
                        variant="outline-primary-color"
                        class="px-4 py-2 rounded-pill mb-2 mr-3"
                        :disabled="isLoading"
                        @click="step = 1"
                      >
                        ย้อนกลับ
                      </b-button>

                      <b-button
                        variant="primary-color"
                        class="px-4 py-2 rounded-pill mb-2"
                        :disabled="isLoading"
                        @click="handleNext(3)"
                      >
                        ต่อไป
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-primary-color"
                        class="px-4 py-2 rounded-pill mb-2 mr-3"
                        :disabled="isLoading"
                        @click="step = 1"
                      >
                        ย้อนกลับ
                      </b-button>
                      <b-button
                        variant="primary-color"
                        class="px-4 py-2 rounded-pill mb-2"
                        :disabled="isLoading || !form.is_privacy_policy"
                        @click="save"
                      >
                        เสร็จสิ้น
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <p class="text-title">ข้อมูลเพิ่มเติม</p>
                <div class="p-3 register-box">
                  <b-row
                    class="mb-3"
                    v-for="(field, index) in dynamicField"
                    :key="'dynamic-' + index"
                  >
                    <b-col cols="4">
                      <p class="text-title-input">
                        {{ field.field_language[0].name }}
                        <span v-if="field.required" class="text-danger">*</span>
                      </p>
                    </b-col>
                    <b-col>
                      <div v-if="field.field_type_id == 1">
                        <InputText
                          v-if="!isLoading"
                          textFloat=""
                          :placeholder="field.name"
                          class="margin-inputf-regular"
                          :type="checkFieldConditon(field)"
                          name="firstname"
                          v-model:value="field.user_answer"
                          :maxlength="checkMaxMin(field.field_conditions, 2)"
                          :min="checkMaxMin(field.field_conditions, 3)"
                          :max="checkMaxMin(field.field_conditions, 4)"
                          :disabled="field.field_profile_type_id == 10"
                          @onDataChange="isMaxMin"
                          :index="index"
                          :isRequired="field.required"
                          @onkeypress="isNumber"
                          :v="$v.dynamicField.$each[index].user_answer"
                          :validateText="field.validate_text"
                          :isValidate="
                            $v.dynamicField.$each.$iter[index].user_answer
                              .$error
                          "
                        />
                      </div>

                      <div
                        v-else-if="
                          field.field_type_id == 2 &&
                          field.field_profile_type_id != 17
                        "
                      >
                        <div class="input-custom mt-2">
                          <b-form-checkbox-group
                            :id="`${field.id}`"
                            size="lg"
                            v-model="field[keyValue]"
                            :options="
                              field.field_choices.map((x) => ({
                                text: x.name,
                                value: x.name,
                              }))
                            "
                            class="ml-2"
                            name="text"
                            :value="field[keyValue]"
                          ></b-form-checkbox-group>
                          <span
                            class="text-danger"
                            v-if="
                              $v.dynamicField.$each.$iter[index].user_answer
                                .$error
                            "
                            >{{ field.validate_text }}</span
                          >
                        </div>
                      </div>
                      <div v-else-if="field.field_profile_type_id == 17">
                        <div class="input-custom">
                          <b-form-checkbox-group
                            size="lg"
                            v-model="field[keyValue]"
                            :options="[{ name: 'Set to default', value: 1 }]"
                            name="name"
                            :value="field[keyValue]"
                          ></b-form-checkbox-group>
                        </div>
                      </div>
                      <div v-else-if="field.field_type_id == 3">
                        <InputRadioGroup
                          textFloat=""
                          v-model="field[keyValue]"
                          className="mb-2"
                          :options="
                            field.field_choices.map((x) => ({
                              text: x.name,
                              value: x.name,
                            }))
                          "
                          @onDataChange="(val) => (field[keyValue] = val)"
                          :v="$v.dynamicField.$each[index].user_answer"
                          :validateText="field.validate_text"
                          :isValidate="
                            $v.dynamicField.$each.$iter[index].user_answer
                              .$error
                          "
                          :isRequired="field.required"
                        />
                      </div>

                      <div v-else-if="field.field_type_id == 4">
                        <InputSelect
                          title=""
                          :options="[
                            {
                              text: field.validate_text,
                              value: '',
                              disabled: true,
                            },
                            ...field.field_choices.map((x) => ({
                              text: x.name,
                              value: x.name,
                            })),
                          ]"
                          :isRequired="field.required"
                          v-model="field[keyValue]"
                          class="f-regular"
                          @onDataChange="
                    (val) =>
                    field[keyValue] =
                        (val),
                  "
                          :v="$v.dynamicField.$each[index].user_answer"
                          :validateText="field.validate_text"
                          :isValidate="
                            $v.dynamicField.$each.$iter[index].user_answer
                              .$error
                          "
                        >
                        </InputSelect>
                      </div>
                      <div v-else-if="field.field_type_id == 7">
                        <InputDatePickerFilter
                          textFloat=""
                          :placeholder="field.field_language[0].name"
                          v-model="field[keyValue]"
                          @input="
                            (val) =>
                            field[keyValue] =
                            (val),
                          "
                          :maxDatetime="now"
                          :v="$v.dynamicField.$each[index].user_answer"
                          :validateText="field.validate_text"
                          :isValidate="
                            $v.dynamicField.$each.$iter[index].user_answer
                              .$error
                          "
                          :isRequired="field.required"
                        />
                      </div>
                      <div v-else-if="field.field_type_id == 5">
                        <datetime
                          type="datetime"
                          :placeholder="field.field_language[0].name"
                          :class="['date-picker-input date-filter']"
                          :format="'dd MMM yyyy HH:mm'"
                          value-zone="Asia/Bangkok"
                          v-model="field[keyValue]"
                          @input="(val) => field[keyValue] = (val), "
                          :maxDatetime="now"
                          :v="$v.dynamicField.$each[index].user_answer"
                          :validateText="field.validate_text"
                          :isValidate="
                            $v.dynamicField.$each.$iter[index].user_answer
                              .$error
                          "
                          :isRequired="field.required"
                        />
                      </div>
                      <div
                        v-else-if="field.field_type_id == 6"
                        class="input-custom"
                      >
                        <InputSelect
                          title=""
                          :text="field.field_language[0].name"
                          :name="field.field_language[0].name"
                          :options="[
                            {
                              text: field.validate_text,
                              value: '',
                              disabled: true,
                            },
                          ]"
                          v-model:value="field[keyValue]"
                          :v="$v.dynamicField.$each[index].user_answer"
                          :validateText="field.validate_text"
                          :isValidate="
                            $v.dynamicField.$each.$iter[index].user_answer
                              .$error
                          "
                          :isRequired="field.required"
                          class="f-regular"
                        />
                      </div>
                      <div v-else-if="field.field_type_id == 10">
                        <h5>{{ field.field_language[0].name }}</h5>
                      </div>
                      <div v-else-if="field.field_type_id == 11">
                        <h5>{{ field.field_language[0].name }}</h5>
                      </div>
                      <div v-else-if="field.field_type_id == 8">
                        <InputUploadImage
                          label=""
                          :img="field[keyValue]"
                          v-model="field[keyValue]"
                          @setFileName="
                            (val) =>
                              setImageName(val, field.field_profile_type_id)
                          "
                          :v="$v.dynamicField.$each[index].user_answer"
                          :validateText="field.validate_text"
                          :isValidate="
                            $v.dynamicField.$each.$iter[index].user_answer
                              .$error
                          "
                          :isRequired="field.required"
                        />
                        <span
                          class="text-danger"
                          v-if="
                            $v.dynamicField.$each.$iter[index].user_answer
                              .$error
                          "
                          >{{ field.validate_text }}</span
                        >
                      </div>
                      <div v-else-if="field.field_type_id == 12">
                        <InputTextArea
                          textFloat=""
                          name="text"
                          rows="3"
                          v-model="field[keyValue]"
                          :v="$v.dynamicField.$each[index].user_answer"
                          :validateText="field.validate_text"
                          :isValidate="
                            $v.dynamicField.$each.$iter[index].user_answer
                              .$error
                          "
                          :isRequired="field.required"
                        />
                      </div>
                    </b-col>
                  </b-row>

                  <p class="desc-privacy mb-2">
                    คลิกเลือกด้านล่าง เพื่อให้เราเข้าใจท่านมากขึ้น
                    และนำเสนอประสบการณ์ที่ดีที่สุดให้กับท่าน / Let us understand
                    you more to deliver you the best experience. Check the box
                    below and stay in touch!
                  </p>
                  <div class="mb-2">
                    <b-form-checkbox
                      v-model="form.is_consent"
                      :value="1"
                      :unchecked-value="0"
                    >
                      <label class="privacy-txt">
                        ฉันต้องการรับสิทธิพิเศษและโปรโมชั่น ตามที่ระบุไว้ใน
                        <router-link to="#" target="_blank"
                          >นโยบายความเป็นส่วนตัว</router-link
                        >
                        / I would like to receive my privileges specified in
                        <router-link to="#" target="_blank"
                          >Privacy Policy</router-link
                        >
                      </label>
                    </b-form-checkbox>
                  </div>
                  <div class="mb-2">
                    <b-form-checkbox
                      v-model="form.is_privacy_policy"
                      :value="1"
                      :unchecked-value="0"
                    >
                      <label class="privacy-txt">
                        ฉันยอมรับ
                        <router-link to="#" target="_blank"
                          >นโยบายความเป็นส่วนตัว</router-link
                        >
                        และ
                        <router-link to="#" target="_blank"
                          >ข้อกำหนดเงื่อนไข</router-link
                        >
                        / I accept the
                        <router-link to="#" target="_blank"
                          >Privacy Policy</router-link
                        >
                        and acknowledge the
                        <router-link to="#" target="_blank"
                          >Terms and Conditions</router-link
                        >
                      </label>
                    </b-form-checkbox>
                  </div>

                  <div class="text-right">
                    <div v-if="form.user_guid">
                      <b-button
                        variant="outline-primary-color"
                        class="px-4 py-2 rounded-pill mb-2"
                        :disabled="isLoading"
                        @click="backRegister"
                      >
                        กลับไป Register
                      </b-button>
                      <b-button
                        variant="outline-primary-color"
                        class="px-4 py-2 rounded-pill mb-2 mx-2"
                        :disabled="isLoading"
                        @click="step = 2"
                      >
                        ย้อนกลับ
                      </b-button>
                      <b-button
                        variant="primary-color"
                        class="px-4 py-2 rounded-pill mb-2"
                        :disabled="isLoading || !form.is_privacy_policy"
                        @click="save"
                      >
                        อัปเดต
                      </b-button>
                    </div>

                    <div v-else>
                      <b-button
                        variant="outline-primary-color"
                        class="px-4 py-2 rounded-pill mb-2 mr-3"
                        :disabled="isLoading"
                        @click="step = 2"
                      >
                        ย้อนกลับ
                      </b-button>
                      <b-button
                        variant="primary-color"
                        class="px-4 py-2 rounded-pill mb-2"
                        :disabled="isLoading || !form.is_privacy_policy"
                        @click="save"
                      >
                        เสร็จสิ้น
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import InputUploadImage from "@/components/inputs/InputUploadImage.vue";
import InputTextArea from "@/components/inputs/InputTextArea";

import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
  requiredIf,
} from "vuelidate/lib/validators";
import InputRadioGroup from "@/components/inputs/InputRadioGroup";
import { mapGetters } from "vuex";
import {
  fieldInForm2,
  profile_fields2,
  profile_fields3,
} from "@/assets/json/fieldProfile.js";

import ProfileFields from "./components/ProfileFields.vue";
import AddressFields from "./components/AddressFields.vue";

export default {
  components: {
    InputTextArea,
    OtherLoading,
    InputSelect,
    InputText,
    InputDatePickerFilter,
    InputRadioGroup,
    InputUploadImage,
    ProfileFields,
    AddressFields,
  },
  name: "NewCustomer",
  props: {
    keyValue: {
      type: String,
      default: () => "user_answer",
      required: false,
    },
  },
  data() {
    return {
      isLoading: true,
      form: {
        id: 0,
        prefix: null,
        gender: null,
        email: null,

        custom_field: [],
        isRegister: true,
      },
      address: {
        country: null,
        subdistrict: null,
        district: null,
        province: null,
      },
      otherGender: "",
      otherPrefix: "",
      fields: [],
      now: null,
      isMember: false,
      step: 1,

      birthday: "",
      dynamicField: [],
    };
  },
  validations() {
    for (const item of this.fields.filter((x) => x.group_id == 1)) {
      const key = this.checkIsProfile(item.field_profile_type_id);

      if (key) {
        if (item.required) {
          if (item.field_profile_type_id == 11) {
            profile_fields2[key] = { email };
          } else if (item.field_profile_type_id == 10)
            profile_fields2[key] = {
              maxLength: maxLength(10),
              minLength: minLength(10),
              numeric,
              required,
            };
          else if (item.field_profile_type_id == 1)
            profile_fields2[key] = {
              maxLength: maxLength(13),
              minLength: minLength(13),
              numeric,
              required,
            };
          else profile_fields2[key] = { required };
        } else {
          if (item.field_profile_type_id == 11) {
            profile_fields2[key] = { email };
          } else if (item.field_profile_type_id == 10) {
            profile_fields2[key] = {
              maxLength: maxLength(10),
              minLength: minLength(10),
              numeric,
            };
          } else if (item.field_profile_type_id == 1) {
            profile_fields2[key] = {
              maxLength: maxLength(13),
              minLength: minLength(13),
              numeric,
            };
          } else profile_fields2[key] = {};
        }
      }
    }

    for (const item of this.fields.filter((x) => x.group_id == 2)) {
      const key = this.checkIsProfile(item.field_profile_type_id);
      if (key) {
        if (item.required && key !== "zip_code") {
          profile_fields3[key] = { required };
        }
      }
    }

    return {
      form: profile_fields2,
      address: profile_fields3,
      dynamicField: {
        $each: {
          user_answer: {
            required: requiredIf(function (item) {
              return item.required;
            }),
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
    }),
  },
  created: async function () {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
    this.isLoading = false;
    this.getField();
  },
  methods: {
    clearData() {
      this.$v.form.$reset();
      this.form = {};
    },
    handleMember(isMember) {
      this.clearData();
      this.isMember = isMember;
    },
    changeBirthday(val) {
      this.form.birthday = val;
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getField() {
      this.isLoading = true;
      const data = await this.$axios.get(
        `${this.$baseUrl}/customer/registerform/1`
      );

      if (data.result === 1) {
        this.fields = data.detail.result.fields.filter((el) => el.is_show == 1);

        this.dynamicField = data.detail.result.custom_field.filter(
          (el) => el.is_show == 1
        );

        for (const field of data.detail.result.custom_field) {
          if (field.field_type_id == 2) {
            field.user_answer = [];
          } else {
            field.user_answer = "";
          }
        }
      }
      this.isLoading = false;
    },

    randomPass(count = false) {
      let result = "";
      const randomCount = count || 32;
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < randomCount) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    async save() {
      this.$v.dynamicField.$touch();

      this.isLoading = true;
      if (this.form.isRegister) {
        await this.fullRegister();
      } else {
        await this.updateCustomerDetail();
      }
      this.isLoading = false;
    },
    fullRegister: async function () {
      if (this.form.birthday) {
        const date = new Date(this.form.birthday);

        this.birthday = {
          birthday_day: String(date.getDate()).padStart(2, "0"),
          birthday_month: String(date.getMonth() + 1).padStart(2, "0"),
          birthday_year: date.getFullYear(),
        };
      }

      let dynamic = [];
      for (const fields of this.dynamicField) {
        let target_id = 0;
        if (fields.field_choices.length > 0) {
          const findFieldId = fields.field_choices.find(
            (choice) => choice.name == fields.user_answer
          );
          if (findFieldId) {
            target_id = findFieldId.id;
          }
        }
        dynamic.push({
          id: fields.id,
          value: fields.user_answer,
          target_id: target_id,
        });
      }

      let objTemp = [];

      for (const x of dynamic) {
        var findField = this.dynamicField.find((field) => field.id == x.id);

        if (x.value && typeof x.value == "object") {
          if (x.value.length > 0) {
            for (const a of x.value) {
              const { id } = findField.field_choices.find(
                (choice) => choice.name == a
              );
              objTemp.push({
                id: x.id,
                value: a,
                target_id: id,
              });
            }
          }
        } else {
          objTemp.push(x);
        }
      }
      let body = {
        ...this.form,
        ...this.birthday,
        gender: this.otherGender || this.form.gender,
        prefix: this.otherPrefix || this.form.prefix,
        custom_field: objTemp.filter((el) => el.value),
      };
      body.telephone ? body.telephone.trim() : "";
      body.email ? body.email.trim() : "";
      body.t_datetime =
        this.dateTimeFix || this.$moment().format("YYYY-MM-DD HH:mm:ss");
      body.password = await this.randomPass(32);

      await this.axios
        .post(`${this.$baseUrl}/customer/registerform/Register`, body)
        .then((data) => {
          if (data.result == 1) {
            this.successAlert();
            this.$router
              .push({
                path: `/customer/success`,
                query: {
                  memberId: data.detail.member_id,
                  name: `${this.form.first_name_th} ${this.form.last_name_th}`,
                  isRegister: "true",
                },
              })
              .catch(() => {});
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    updateCustomerDetail: async function () {
      let body = { ...this.form, ...this.address };
      body.telephone = body.telephone.trim();
      body.birthday = this.$moment(body.birthday).format("YYYY-MM-DD HH:mm:ss");
      body.the_one_card_member = parseInt(body.the_one_card_member);

      await this.axios
        .post(`${this.$baseUrl}/customer/update_customer_detail`, body)
        .then((data) => {
          if (data.result == 1) {
            // this.successAlert();
            this.$router
              .push({
                path: `/customer/success`,
                query: {
                  memberId: "",
                  name: `${body.firstname} ${body.lastname}`,
                  isRegister: "false",
                },
              })
              .catch(() => {});
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    async setImageName(val, id) {
      let res = await this.checkIsProfile(id);
      if (res == "id_card_image") this.form.id_card_file_name = val;
    },
    login: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      this.error = "";
      let body = {
        birthday: this.form.birthday,
        telephone: this.form.telephone.trim(),
      };

      this.axios
        .post(`${this.$baseUrl}/customer/login_with_phonenumber`, body)
        .then(async (data) => {
          if (data.result == 1) {
            this.$v.form.$reset();
            this.form = { ...data.detail };
            this.form.firstname = data.detail.first_name_th;
            this.form.lastname = data.detail.last_name_th;
            this.form.email = data.detail.email.trim();
            this.form.isRegister = false;
            this.form.is_privacy_policy = 1;
            this.step = 1;
            this.isMember = false;
          } else {
            this.warningAlert(data.message);
          }
          this.isLoading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleNext(goto) {
      if (goto == 2) {
        this.$v.form.$touch();

        if (this.$v.form.$error) return;
      } else {
        this.$v.address.$touch();

        if (this.$v.address.$error) return;
      }
      this.step = goto;
    },

    checkFieldConditon(obj) {
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },
    checkIsProfile(val) {
      if (val == 99) val = 22;
      const findField = fieldInForm2.find((el) => el.id == val);
      return findField ? findField.key : false;
    },
    backRegister() {
      this.form.isRegister = true;
      this.clearData();
      this.step = 1;
    },
    checkMaxMin(item, key, field_id) {
      if (!item) return false;
      if (field_id == 9) {
        const condition = item.find((el) => el.field_condition_type_id == 7);

        if (condition)
          if (key == 3) {
            return condition.value
              ? this.$moment()
                  .subtract(condition.value, "year")
                  .format("YYYY-01-01")
              : false;
          } else if (key == 4) return this.$moment().format("YYYY-MM-DD");
      }
      const conditon = item.find((el) => el.field_condition_type_id == key);
      if (conditon) return conditon.value ? conditon.value : false;
      return false;
    },
    isMaxMin: function (value, e) {
      try {
        const val = e.target.value;

        const max = Number(e.target.max);
        const min = Number(e.target.min);
        if (val && e.target.type == "number" && min && val < min) {
          return (e.target.value = min);
        }
        if (val && e.target.type == "number" && max && val > max) {
          return (e.target.value = max);
        }
      } catch (error) {
        console.log(error);
      }
    },
    isNumber: function (e) {
      const maxlen = e.target.maxLength;
      const val = e.target.value;

      if (
        (e.key == "e" || e.charCode == 45 || e.charCode == 46) &&
        e.target.type == "number"
      ) {
        return e.preventDefault();
      }
      if (maxlen == -1) {
        return val;
      }

      if (val.length > maxlen - 1) {
        return e.preventDefault();
      }
    },
    checkFieldConditon(obj) {
      if (!obj.field_conditions) return "text";
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },
    handleOtherForm(val, field) {
      if (field == "prefix") {
        this.otherPrefix = val;
      } else this.otherGender = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.register-bg {
  background-image: url(./../../../../assets/images/icons/register-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  // position: relative;
}
.loading {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 500;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: rgba(255, 255, 255, 0.5);
}
.header-panel {
  text-align: center;
  padding: 10px;
  color: #fff;
  font-size: 20px;
  background-color: var(--primary-color);
}
::v-deep .body-panel {
  background-color: #ffffff;
  border: 3px solid var(--primary-color) !important;
  .text-title {
    text-align: center;
    color: var(--primary-color);
    padding: 10px 0;
    font-size: 20px;
  }
  .text-title-input {
    color: #707070;
    font-size: 16px;
    font-weight: normal;
    padding-top: 5px;
  }
  .picture {
    width: 100%;
    height: 100%;
    background-image: url(./../../../../assets/images/icons/register_banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--primary-color);
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }
  .btn-link {
    background-color: transparent !important;
    color: var(--primary-color) !important;
    text-decoration: underline;
    padding: 0;
  }
  .desc-privacy {
    font-size: 12px;
  }
  .privacy-txt {
    color: #707070;
    display: inline;
    font-size: 12px;
    a {
      color: #707070 !important;
      text-decoration: underline;
    }
  }
  // .register-box {
  //   min-height: 670px;
  // }
}

.title {
  color: #333 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100%;
  // position: relative;
}

.card-container {
  max-height: 80vh;
  overflow: auto;
}
</style>
